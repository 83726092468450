<template>
  <div>
    <h4 class="ml-3 font-weight-light">{{ title }}</h4>
    <div class="row" v-if="renderGraphic">
      <div class="col-12 col-w-full">
        <WhiteLabel>
          <div class="row mb-1 mx-0 mb-3 p-0">
            <label
              class="
                radio-selector
                mx-auto
                col-12 col-w-full col-md-4
                px-0
                mb-3
              "
              @click.stop="changeYear()"
            >
              <input type="radio" name="sales" value="A" checked />
              <span class="yellow-card-radio">Anual</span>
            </label>
            <label
              class="
                radio-selector
                mx-auto
                col-12 col-w-full col-md-4
                px-0
                mb-3
              "
              @click.stop="changeMonth()"
            >
              <input type="radio" name="sales" value="M" />
              <span class="yellow-card-radio">Mensal</span>
            </label>
            <label
              class="
                radio-selector
                mx-auto
                col-12 col-w-full col-md-4
                px-0
                mb-3
              "
              @click.stop="changeWeek()"
            >
              <input type="radio" name="sales" value="S" />
              <span class="yellow-card-radio">Dias da semana</span>
            </label>
          </div>
        </WhiteLabel>
      </div>
      <div class="col-md-12">
        <div class="d-block">
          <WhiteLabel>
            <apexchart
              type="bar"
              :options="options"
              :series="series"
            ></apexchart>
          </WhiteLabel>
        </div>
      </div>
    </div>
    <div class="row" v-if="!renderGraphic">
      <h1 class="font-weight-light mx-auto text-center text-muted">
        <i class="icon-chart"></i>
        <br />Sem dados no momento
      </h1>
    </div>
  </div>
</template>

<script>
import PaymentsApi from '@/services/PaymentsApi';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppSalesStatisticsBar',

  components: {
    WhiteLabel,
  },

  data: () => ({
    title: 'Movimentação financeira',
    renderGraphic: true,
    activeCategory: '',
    options: {
      chart: {
        id: 'SalesStatisticsBar',
        foreColor: '#000000',
      },
      xaxis: {
        categories: [''],
      },
      tooltip: {
        enabled: true,
        theme: 'light',
        marker: {
          show: false,
        },
        x: {
          show: false,
        },
        y: {
          formatter: function (val) {
            return (
              'R$ ' +
              val
                .toFixed(2)
                .toString()
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            );
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '80%',
          barHeight: '100%',
          colors: {
            ranges: [
              {
                from: 0,
                to: 999999,
                color: '#FF920B',
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: '',
        data: [0],
      },
    ],
  }),

  async mounted() {
    const api = new PaymentsApi();
    const salesStatistics = (await api.getSalesStatisticsYearly()).statistics;
    if (salesStatistics.length === 0) {
      this.renderGraphic = false;
    }

    const categoryName = 'year';
    this.activeCategory = categoryName;
    this.createChart(salesStatistics, categoryName);

    this.$emit('ready');
  },

  methods: {
    async changeYear() {
      const categoryName = 'year';
      if (!this.checkChartCategory(categoryName)) return;

      this.$bus.$emit('spinner-run');

      const api = new PaymentsApi();
      const salesStatistics = (await api.getSalesStatisticsYearly()).statistics;

      this.$bus.$emit('spinner-stop');

      if (salesStatistics.length === 0) {
        this.renderGraphic = false;
      } else {
        this.renderGraphic = true;
      }
      this.createChart(salesStatistics, categoryName);
    },
    async changeMonth() {
      const categoryName = 'month';
      if (!this.checkChartCategory(categoryName)) return;

      this.$bus.$emit('spinner-run');

      const api = new PaymentsApi();
      const salesStatistics = (await api.getSalesStatisticsMonthly())
        .statistics;

      this.$bus.$emit('spinner-stop');

      if (salesStatistics.length === 0) {
        this.renderGraphic = false;
      } else {
        this.renderGraphic = true;
      }

      for (let index = 0; index < salesStatistics.length; index++) {
        if (salesStatistics[index].month === 1) {
          salesStatistics[index].month = 'Jan';
        } else if (salesStatistics[index].month === 2) {
          salesStatistics[index].month = 'Fev';
        } else if (salesStatistics[index].month === 3) {
          salesStatistics[index].month = 'Mar';
        } else if (salesStatistics[index].month === 4) {
          salesStatistics[index].month = 'Abr';
        } else if (salesStatistics[index].month === 5) {
          salesStatistics[index].month = 'Mai';
        } else if (salesStatistics[index].month === 6) {
          salesStatistics[index].month = 'Jun';
        } else if (salesStatistics[index].month === 7) {
          salesStatistics[index].month = 'Jul';
        } else if (salesStatistics[index].month === 8) {
          salesStatistics[index].month = 'Ago';
        } else if (salesStatistics[index].month === 9) {
          salesStatistics[index].month = 'Set';
        } else if (salesStatistics[index].month === 10) {
          salesStatistics[index].month = 'Out';
        } else if (salesStatistics[index].month === 11) {
          salesStatistics[index].month = 'Nov';
        } else if (salesStatistics[index].month === 12) {
          salesStatistics[index].month = 'Dez';
        }
      }
      this.createChart(salesStatistics, categoryName);
    },

    async changeWeek() {
      const categoryName = 'dayOfWeek';
      if (!this.checkChartCategory(categoryName)) return;

      this.$bus.$emit('spinner-run');

      const api = new PaymentsApi();
      const salesStatistics = (await api.getSalesStatisticsWeekly()).statistics;

      this.$bus.$emit('spinner-stop');

      if (salesStatistics.length === 0) {
        this.renderGraphic = false;
      } else {
        this.renderGraphic = true;
      }

      for (let index = 0; index < salesStatistics.length; index++) {
        if (salesStatistics[index].dayOfWeek === 1) {
          salesStatistics[index].dayOfWeek = 'Segunda';
        }
        if (salesStatistics[index].dayOfWeek === 2) {
          salesStatistics[index].dayOfWeek = 'Terça';
        }
        if (salesStatistics[index].dayOfWeek === 3) {
          salesStatistics[index].dayOfWeek = 'Quarta';
        }
        if (salesStatistics[index].dayOfWeek === 4) {
          salesStatistics[index].dayOfWeek = 'Quinta';
        }
        if (salesStatistics[index].dayOfWeek === 5) {
          salesStatistics[index].dayOfWeek = 'Sexta';
        }
        if (salesStatistics[index].dayOfWeek === 6) {
          salesStatistics[index].dayOfWeek = 'Sábado';
        }
        if (salesStatistics[index].dayOfWeek === 0) {
          salesStatistics[index].dayOfWeek = 'Domingo';
        }
        continue;
      }
      this.createChart(salesStatistics, categoryName);
    },

    async createChart(salesStatistics, categoryName) {
      var size = this.series[0].data.length;
      for (var i = 0; i < size; i++) {
        this.series[0].data.pop();
        this.options.xaxis.categories.pop();
      }

      for (var i = 0; i < salesStatistics.length; i++) {
        if (categoryName === 'year') {
          this.series[0].data.push(salesStatistics[i].amount);
          this.options.xaxis.categories.push(
            salesStatistics[i].year.toString()
          );
        } else if (categoryName === 'month') {
          this.series[0].data.push(salesStatistics[i].amount);
          this.options.xaxis.categories.push(
            salesStatistics[i].month.toString()
          );
        } else if (categoryName === 'dayOfWeek') {
          this.series[0].data.push(salesStatistics[i].amount);
          this.options.xaxis.categories.push(salesStatistics[i].dayOfWeek);
        }
      }
    },

    checkChartCategory(categoryName) {
      if (categoryName == this.activeCategory) return false;
      this.activeCategory = categoryName;
      return true;
    },
  },
};
</script>
