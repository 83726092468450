<template>
  <div>
    <h4 class="ml-3 font-weight-light">{{ title }}</h4>
    <div class="row" v-if="renderCards">
      <div class="col-6">
        <div class="data-card">
          <div class="sales-quantitative col-12">{{ cardBrandAverage }}</div>
          <div class="totalizer-title mb-3 col-12">Transações no cartão</div>
        </div>
      </div>
      <div class="col-6">
        <div class="data-card">
          <div class="sales-quantitative col-12">{{ bankSlipAverage }}</div>
          <div class="totalizer-title mb-3 col-12">Transações no boleto</div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!renderCards">
      <h1 class="font-weight-light mx-auto text-center text-muted">
        <i class="icon-chart"></i>
        <br />Sem dados no momento
      </h1>
    </div>
  </div>
</template>

<script>
import DashboardApi from '@/services/v1/DashboardApi';
import { PartnerAtp } from '@/models/Dashboard';

export default {
  name: 'PartnerAtp',
  async mounted() {
    this.fillAverages();
    this.$emit('ready');
  },
  data() {
    return {
      title: 'Ticket médio de transações',
      renderCards: true,
      cardBrandAverage: 0,
      bankSlipAverage: 0,
    };
  },
  methods: {
    async fillAverages() {
      const api = new DashboardApi();
      const { cardBrandAverage, bankSlipAverage } = await api.getPartnerAtp();

      if (cardBrandAverage === 0 && bankSlipAverage === 0) {
        this.renderCards = false;

        return;
      }

      this.cardBrandAverage = cardBrandAverage;
      this.bankSlipAverage = bankSlipAverage;
    },
  },
};
</script>
<style scoped>
.data-card {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.15);
  color: #adb5bd;
  padding: 1rem 1rem;
  border-radius: 1rem;
}
</style>
