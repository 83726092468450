<template>
  <div>
    <h4 class="ml-3 font-weight-light">{{ title }}</h4>
    <div class="row">
      <div class="col-12 col-w-full">
        <div class="d-block">
          <WhiteLabel>
            <apexchart :options="options" :series="series"></apexchart>
          </WhiteLabel>
        </div>
      </div>
    </div>
    <div class="row" v-if="!renderGraphic">
      <h1 class="font-weight-light mx-auto text-center text-muted">
        <i class="icon-chart"></i>
        <br />Sem dados no momento
      </h1>
    </div>
  </div>
</template>

<script>
import DashboardApi from '@/services/v1/DashboardApi';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'PartnerCardsBrands',
  components: { WhiteLabel },
  async mounted() {
    this.fillCardsBrandsBar();
    this.$emit('ready');
  },
  data() {
    return {
      title: 'Percentual de contestação por bandeira',
      renderGraphic: true,
      options: {
        chart: {
          id: 'AppCardsBrandsPerPeriod',
          forecolor: '#000000',
          type: 'line',
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          enabled: true,
          x: {
            show: false,
          },
          marker: {
            show: false,
          },
          x: {
            show: false,
          },
          y: {
            color: '#808080',
            formatter: '%',
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '90%',
            barHeight: '100%',
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 99999999,
                  color: '#FF920B',
                },
              ],
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: [this.fillCardsBrandsBar],
      },
      series: [
        {
          name: 'Bandeira',
          type: 'bar',
          data: [],
        },
        {
          name: 'Limite permitido',
          type: 'line',
          data: [],
        },
      ],
    };
  },
  methods: {
    async fillCardsBrandsBar() {
      const api = new DashboardApi();
      const sellerTaxDocument = this.getSeller();
      const cardsBrandsBar = await api.getPartnerCardContesting(
        sellerTaxDocument
      );
      let bars = cardsBrandsBar.cardBrands.length;

      if (bars === 0) {
        this.renderGraphic = true;
      }

      for (var i = 0; i < bars; i++) {
        this.series[0].data.push(cardsBrandsBar.cardBrands[i].percentage);
        this.options.xaxis.categories.push(
          cardsBrandsBar.cardBrands[i].cardBrand
        );

        this.series[1].data.push(cardsBrandsBar.maxContestingAllowed);
      }
    },
    getSeller() {
      return window.localStorage.getItem('dashboardSeller') || null;
    },
  },
};
</script>
