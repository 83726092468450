import axios from 'axios';

export default class DashboardApi {
  async getPartnerBankSlip(sellerTaxDocument) {
    const params = {
      sellerTaxDocument,
    };
    const result = await axios.get(`${process.env.VUE_APP_BI}/bank-slip`, { params });
    return result.data;
  }

  async getPartnerCardContesting(sellerTaxDocument) {
    const params = {
      sellerTaxDocument,
    };
    const result = await axios.get(`${process.env.VUE_APP_BI}/card/contesting`, { params });
    return result.data;
  }

  async getPartnerAtp(sellerTaxDocument) {
    const params = {
      sellerTaxDocument,
    };
    const result = await axios.get(`${process.env.VUE_APP_BI}/atp`, { params });
    return result.data;
  }

  async getPartnerFinancialEvolution(sellerTaxDocument) {
    const params = {
      sellerTaxDocument,
    };
    const result = await axios.get(`${process.env.VUE_APP_BI}/financial-evolution`, { params });
    return result.data;
  }

  async getPartnerComissionEvolution(sellerTaxDocument) {
    const params = {
      sellerTaxDocument,
    };
    const result = await axios.get(`${process.env.VUE_APP_BI}/commission-evolution`, { params });
    return result.data;
  }
}
