export class PartnerBankSlipData {
  paid = 0;
  pending = 0;
  delayed = 0;
  expired = 0;
  canceled = 0;
}

export class PartnerCardContesting {
  cardBrands = [
    { cardBrand: 'visa', percentage: 0 },
    { cardBrand: 'elo', percentage: 0 },
    { cardBrand: 'mastercard', percentage: 0 },
    { cardBrand: 'amex', percentage: 0 },
    { cardBrand: 'diners', percentage: 0 },
  ]
}

export class PartnerAtp {
  cardBrandAverage = 1200;
  bankSlipAverage = 200;
}

export class PartnerFinancialEvolution {
  sellerStatistics = null;
  statistics = [
    { month: 12, year: 0, amount: 0 },
    { month: 11, year: 0, amount: 0 },
    { month: 10, year: 0, amount: 0 },
    { month: 9, year: 0, amount: 0 },
    { month: 8, year: 0, amount: 0 },
    { month: 7, year: 0, amount: 0 },
    { month: 6, year: 0, amount: 0 },
    { month: 5, year: 0, amount: 0 },
    { month: 4, year: 0, amount: 0 },
    { month: 3, year: 0, amount: 0 },
    { month: 2, year: 0, amount: 0 },
    { month: 1, year: 0, amount: 0 },
  ];
}

export class PartnerComissionEvolution {
  sellerStatistics = null;
  statistics = [
    { month: 12, year: 0, amount: 0 },
    { month: 11, year: 0, amount: 0 },
    { month: 10, year: 0, amount: 0 },
    { month: 9, year: 0, amount: 0 },
    { month: 8, year: 0, amount: 0 },
    { month: 7, year: 0, amount: 0 },
    { month: 6, year: 0, amount: 0 },
    { month: 5, year: 0, amount: 0 },
    { month: 4, year: 0, amount: 0 },
    { month: 3, year: 0, amount: 0 },
    { month: 2, year: 0, amount: 0 },
    { month: 1, year: 0, amount: 0 },
  ];
}
