<template>
  <div class="container">
    <div class="row">
      <h3 class="col-4">
        <span class="ml-3">Transações</span>
        <small class="font-weight-light"> - nos últimos 12 meses</small>
      </h3>
      <div class="col-8 mb-4" v-if="isNavigation('partner')">
        <div class="pull-right">
          <select
            class="form-control text-center"
            v-model="seller"
            @change="handleSellerSelect"
          >
            <option value>Selecione um estabelecimento</option>
            <option
              v-for="seller in sellers"
              :key="seller.id"
              :value="seller.taxDocument"
            >
              {{ seller.fullName }}
              ({{ seller.taxDocument }})
            </option>
          </select>
          <WhiteLabel class="btn__remove-filter" v-if="this.seller !== ''">
            <a href="#" class="text-uppercase text-sm p-0" @click="resetSeller"
              >limpar filtro</a
            >
          </WhiteLabel>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-w-full col-lg-6">
        <div class="card-dashboard">
          <partner-bank-slip
            v-if="isNavigation('partner')"
            @ready="setChartReady(charts.partner.partnerBankSlip)"
          />
          <app-sales-amount
            v-if="!isNavigation('partner')"
            @ready="setChartReady(charts.holder.salesAmount)"
          />
        </div>
      </div>
      <div class="col-12 col-w-full col-lg-6">
        <div class="card-dashboard">
          <partner-cards-brands
            v-if="isNavigation('partner')"
            @ready="setChartReady(charts.partner.partnerCardsBrands)"
          />
          <app-sales-per-period
            v-if="!isNavigation('partner')"
            @ready="setChartReady(charts.holder.salesPerPeriod)"
          />
        </div>
      </div>
      <div class="col-12 col-w-full col-lg-6">
        <div class="card-dashboard">
          <partner-financial-evolution
            v-if="isNavigation('partner')"
            @ready="setChartReady(charts.partner.partnerFinancialEvolution)"
          />
          <app-bank-slip-month
            v-if="!isNavigation('partner')"
            @ready="setChartReady(charts.holder.bankSlipMonth)"
          />
        </div>
      </div>
      <div class="col-12 col-w-full col-lg-6">
        <div class="card-dashboard">
          <partner-comission-evolution
            v-if="isNavigation('partner')"
            @ready="setChartReady(charts.partner.partnerComissionEvolution)"
          />
          <app-sales-statistics-bar
            v-if="!isNavigation('partner')"
            @ready="setChartReady(charts.holder.salesStatisticsBar)"
          />
        </div>
      </div>
      <div class="col-12 col-w-full col-lg-6">
        <div class="card-dashboard">
          <partner-atp
            v-if="isNavigation('partner')"
            @ready="setChartReady(charts.partner.partnerAtp)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppSalesAmount from '@/components/dashboard/sales-amount/SalesAmount';
import AppBankSlipMonth from '@/components/dashboard/bankslip-month/BankSlipMonth';
import AppSalesStatisticsBar from '@/components/dashboard/sales-statistics/SalesStatisticsBar';
import AppSalesPerPeriod from '@/components/dashboard/sales-per-period/SalesPerPeriod';
import PartnerBankSlip from '@/components/dashboard/partner/BankSlip';
import PartnerCardsBrands from '@/components/dashboard/partner/CardsBrands';
import PartnerAtp from '@/components/dashboard/partner/Atp';
import PartnerFinancialEvolution from '@/components/dashboard/partner/FinancialEvolution';
import PartnerComissionEvolution from '@/components/dashboard/partner/ComissionEvolution';
import WhiteLabel from '@/components/shared/WhiteLabel';
import AccountApi from '@/services/AccountApi';

export default {
  name: 'AppCharts',
  components: {
    AppBankSlipMonth,
    AppSalesAmount,
    AppSalesStatisticsBar,
    AppSalesPerPeriod,
    PartnerBankSlip,
    PartnerCardsBrands,
    PartnerAtp,
    PartnerFinancialEvolution,
    PartnerComissionEvolution,
    WhiteLabel,
  },
  data: () => ({
    settings: null,
    sellers: [],
    seller: window.localStorage.getItem('dashboardSeller') || '',
    charts: {
      partner: {
        partnerBankSlip: { ready: false },
        partnerCardsBrands: { ready: false },
        partnerAtp: { ready: false },
        partnerFinancialEvolution: { ready: false },
        partnerComissionEvolution: { ready: false },
      },
      holder: {
        salesStatisticsBar: { ready: false },
        bankSlipMonth: { ready: false },
        salesAmount: { ready: false },
        salesPerPeriod: { ready: false },
      },
      current: {},
    },
  }),
  async mounted() {
    this.$bus.$emit('spinner-run');

    if (this.isNavigation('partner')) {
      await this.getSellers();
    }

    this.charts.current = this.isNavigation('partner')
      ? this.charts.partner
      : this.charts.holder;
  },
  methods: {
    async getSellers() {
      const api = new AccountApi();
      const result = await api.getSellers({
        length: 50,
      });

      this.sellers = result.data.sellers;
    },
    setChartReady(chart) {
      chart.ready = true;

      var currentAsArray = Object.entries(this.charts.current);

      // object.entries returns a array with [0] being the name and [1] the object
      if (currentAsArray.every((x) => x[1].ready)) {
        this.$bus.$emit('spinner-stop');
      }
    },
    resetSeller() {
      this.$bus.$emit('spinner-run');
      this.seller = '';
      window.localStorage.removeItem('dashboardSeller');
      window.location = '/';
    },
    handleSellerSelect() {
      this.$bus.$emit('spinner-run');
      window.localStorage.setItem('dashboardSeller', this.seller);
      window.location = '/';
    },
    isNavigation(criteria) {
      return window.localStorage.getItem('navigation') === criteria;
    },
  },
};
</script>

<style scoped>
.btn__remove-filter {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
}
</style>
