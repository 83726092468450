<template>
  <div>
    <h4 class="ml-3 font-weight-light">{{ title }}</h4>
    <div class="row">
      <div class="col-12 col-w-full">
        <div class="d-block">
          <WhiteLabel>
            <apexchart
              class="line-chart"
              type="line"
              :options="options"
              :series="series"
            ></apexchart>
          </WhiteLabel>
        </div>
      </div>
    </div>
    <div class="row" v-if="!renderGraphic">
      <h1 class="font-weight-light mx-auto text-center text-muted">
        <i class="icon-chart"></i>
        <br />Sem dados no momento
      </h1>
    </div>
  </div>
</template>

<script>
import DashboardApi from '@/services/v1/DashboardApi';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'PartnerFinancialEvolution',
  components: { WhiteLabel },
  async mounted() {
    await this.fillLines();
    this.$emit('ready');
  },
  data() {
    return {
      title: 'Evolução Financeira',
      renderGraphic: true,
      options: {
        chart: {
          id: 'ParterFinancialEvolution',
          forecolor: '#000000',
        },
        xaxis: {
          categories: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ],
        },
        tooltip: {
          enabled: true,
          x: {
            show: false,
          },
          marker: {
            show: false,
          },
          y: {
            color: '#808080',
            formatter: '%',
          },
        },
        dataLabels: {
          enabled: false,
        },
      },
      series: [],
    };
  },
  methods: {
    async fillLines() {
      const api = new DashboardApi();
      const sellerTaxDocument = this.getSeller();
      const lineData = await api.getPartnerFinancialEvolution(
        sellerTaxDocument
      );

      this.series.push({
        name: this.getSeller ? 'Parceiro' : 'R$',
        type: 'line',
        data: [],
      });

      let seriesData = this.series[0].data;

      for (let index in lineData.statistics) {
        seriesData.push(lineData.statistics[index].amount);
      }

      seriesData.reverse();

      if (sellerTaxDocument && lineData.sellerStatistics !== null) {
        this.series.push({
          name: 'Estabelecimento',
          type: 'line',
          data: [],
        });

        let sellerSeriesData = this.series[1].data;

        for (let index in lineData.sellerStatistics) {
          sellerSeriesData.push(
            lineData.sellerStatistics[index].amount.toFixed(2).replace('.', ',')
          );
        }

        sellerSeriesData.reverse();
      }
    },
    getSeller() {
      return window.localStorage.getItem('dashboardSeller') || null;
    },
  },
};
</script>
