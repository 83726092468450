<template>
  <div>
    <h4 class="ml-3 font-weight-light">{{ title }}</h4>
    <div class="row">
      <div class="donuts-card mt-4" v-if="renderGraphic">
        <apexchart
          width="500"
          type="donut"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
    <div class="row" v-if="!renderGraphic">
      <h1 class="font-weight-light mx-auto text-center text-muted">
        <i class="icon-chart"></i>
        <br />Sem dados no momento
      </h1>
    </div>
  </div>
</template>

<script>
import DashboardApi from '@/services/v1/DashboardApi';
import { PartnerBankSlipData } from '@/models/Dashboard';

export default {
  name: 'PartnerBankSlip',
  async mounted() {
    await this.fillBankSlips();
    this.$emit('ready');
  },
  data() {
    return {
      title: 'Boletos emitidos',
      renderGraphic: true,
      options: {
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '20px',
                  color: '#adadad',
                  offsetY: -30,
                },
                value: {
                  fontSize: '50px',
                  align: 'top',
                  color: '#808080',
                  fontFamily: 'Roboto',
                  offsetY: 10,
                  formatter: function (val) {
                    return val + '%';
                  },
                },
              },
            },
          },
        },
        colors: ['#31B0E5', '#FF5723', '#00D09D', 'red', 'green', 'blue'],
        labels: ['Pagos', 'Pendentes', 'Atrasados', 'Vencidos', 'Cancelados'],
      },
      series: [],
    };
  },
  methods: {
    async fillBankSlips() {
      const api = new DashboardApi();
      const sellerTaxDocument = this.getSeller();

      let bankSlip = await api.getPartnerBankSlip(sellerTaxDocument);

      if (bankSlip === null) {
        bankSlip = new PartnerBankSlipData();
      }

      this.series = [
        bankSlip.paid.percentage,
        bankSlip.pending.percentage,
        bankSlip.delayed.percentage,
        bankSlip.expired.percentage,
        bankSlip.canceled.percentage,
      ];
    },
    getSeller() {
      return window.localStorage.getItem('dashboardSeller') || null;
    },
  },
};
</script>
