<template>
  <div>
    <h4 class="ml-3 font-weight-light">{{ title }}</h4>
    <div class="row">
      <div class="col-12 col-w-full">
        <div class="d-block">
          <WhiteLabel>
            <apexchart
              type="bar"
              :options="options"
              :series="series"
            ></apexchart>
          </WhiteLabel>
        </div>
      </div>
    </div>
    <div class="row" v-if="!renderGraphic">
      <h1 class="font-weight-light mx-auto text-center text-muted">
        <i class="icon-chart"></i>
        <br />Sem dados no momento
      </h1>
    </div>
  </div>
</template>

<script>
import PaymentsApi from '@/services/PaymentsApi';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppSalesPerPeriod',
  components: { WhiteLabel },
  async mounted() {
    await this.fillSalesAmountBar();

    this.$emit('ready');
  },
  data() {
    return {
      title: 'Horários com maior volume de transações',
      renderGraphic: true,
      options: {
        chart: {
          id: 'AppSalesPerPeriod',
          forecolor: '#000000',
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          enabled: true,
          x: {
            show: false,
          },
          marker: {
            show: false,
          },
          x: {
            show: false,
          },
          y: {
            color: '#808080',
            formatter: function (val) {
              return (
                val
                  .toFixed(2)
                  .toString()
                  .replace('.', ',')
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' transações'
              );
            },
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '90%',
            barHeight: '100%',
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 99999999,
                  color: '#FF920B',
                },
              ],
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: [this.fillSalesAmountBar],
      },
      series: [
        {
          name: '',
          data: [],
        },
      ],
    };
  },
  methods: {
    async fillSalesAmountBar() {
      const api = new PaymentsApi();
      const salesAmountBar = await api.getSalesStatisticsHourly();
      var bars = salesAmountBar.statistics.length;

      if (bars === 0) {
        this.renderGraphic = true;
      }

      for (var i = 0; i < bars; i++) {
        this.series[0].data.push(salesAmountBar.statistics[i].amount);
        this.options.xaxis.categories.push(
          salesAmountBar.statistics[i].hour.toString()
        );
      }
    },
  },
};
</script>
