<template>
  <div>
    <h4 class="ml-3 font-weight-light">{{ title }}</h4>
    <div class="row">
      <div class="donuts-card mt-4" v-if="renderGraphic">
        <apexchart
          width="500"
          type="donut"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
    <div class="row" v-if="!renderGraphic">
      <h1 class="font-weight-light mx-auto text-center text-muted">
        <i class="icon-chart"></i><br />Sem dados no momento
      </h1>
    </div>
  </div>
</template>

<script>
import PaymentsApi from '@/services/PaymentsApi';
import { futimes } from 'fs';
import { format } from 'path';
export default {
  name: 'AppSalesAmount',
  async mounted() {
    $.when(this.fillSalesAmount());
  },
  data() {
    return {
      title: 'Transações por modalidade',
      renderGraphic: true,
      options: {
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '20px',
                  color: '#adadad',
                  offsetY: -30,
                },
                value: {
                  fontSize: '50px',
                  offsetY: 5,
                  color: '#808080',
                  fontFamily: 'Roboto',
                  formatter: function (val) {
                    return val + '%';
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        colors: ['#31B0E5', '#FF5723', '#8865D6', '#FFAD00', '#00D09D'],
        labels: [
          'Boleto',
          'À vista',
          'Crédito (2x a 6x)',
          'Crédito (7x a 12x)',
          'Débito',
        ],
      },
      series: [],
    };
  },
  methods: {
    async fillSalesAmount() {
      const api = new PaymentsApi();
      const sales = await api.getSalesStatisticsPaymentMethods();
      if (
        sales.bankSlip === 100 &&
        sales.credit.installmentSevenTwelve === 0 &&
        sales.credit.installmentTwoSix === 0 &&
        sales.credit.singleInstallment === 0 &&
        sales.debit === 0
      ) {
        this.renderGraphic = false;
      }
      this.series = [
        sales.bankSlip,
        sales.credit.singleInstallment,
        sales.credit.installmentTwoSix,
        sales.credit.installmentSevenTwelve,
        sales.debit,
      ];

      this.$emit('ready');
    },
  },
};
</script>
