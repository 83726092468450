<template>
  <div>
    <h4 class="ml-3 font-weight-light">{{ title }}</h4>
    <div class="row">
      <div class="donuts-card mt-4" v-if="renderGraphic">
        <apexchart
          width="500"
          type="donut"
          :options="options"
          :series="series"
        ></apexchart>
      </div>
    </div>
    <div class="row" v-if="!renderGraphic">
      <h1 class="font-weight-light mx-auto text-center text-muted">
        <i class="icon-chart"></i><br />Sem dados no momento
      </h1>
    </div>
  </div>
</template>

<script>
import PaymentsApi from '@/services/PaymentsApi';
import { BankSlipData } from '@/models/BankSlipData';

export default {
  name: 'AppBankSlipMonth',
  async mounted() {
    $.when(this.fillBankSlips());
  },
  data() {
    return {
      title: 'Boletos emitidos neste mês',
      renderGraphic: true,
      options: {
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '20px',
                  color: '#adadad',
                  offsetY: -30,
                },
                value: {
                  fontSize: '50px',
                  align: 'top',
                  color: '#808080',
                  fontFamily: 'Roboto',
                  offsetY: 10,
                  formatter: function (val) {
                    return val + '%';
                  },
                },
              },
            },
          },
        },
        colors: ['#31B0E5', '#FF5723', '#00D09D'],
        labels: ['Vencidos', 'Pagos', 'Pendentes'],
      },
      series: [],
    };
  },
  methods: {
    async fillBankSlips() {
      const api = new PaymentsApi();

      let bankSlip = await api.getPaymentSlipsCurrentMonth();
      if (
        bankSlip.delayed.quantity === 0 &&
        bankSlip.paid.quantity === 0 &&
        bankSlip.pending.quantity === 0
      ) {
        this.renderGraphic = false;
      }
      if (bankSlip === null) {
        bankSlip = new BankSlipData();
      }
      this.series = [
        bankSlip.delayed.percentage,
        bankSlip.paid.percentage,
        bankSlip.pending.percentage,
      ];

      this.$emit('ready');
    },
  },
};
</script>
